<template>
  <header class="mm-client-header">
    <HeaderGodMode v-if="mpAdminInfoEmail" />

    <div class="mm-client-header__container">
      <div class="mm-client-header__logo logo">
        <NuxtLink to="/">
          <BaseImg
            :src="logoSrc"
            alt=""
            class="logo--desc-off"
          />
        </NuxtLink>
      </div>

      <ul
        v-if="isHeaderMenuRendered()"
        class="mm-client-header__menu"
      >
        <li
          v-for="link in clientMenu"
          id="user_menu"
          :key="link.label"
          class="mm-client-header__menu-item"
        >
          <NuxtLink
            :to="link.path"
            :target="link.target"
            :class="{
              'mm-client-header__menu-link': true,
              'mm-client-header__menu-link--active': isActiveLink(link),
            }"
            active-class="mm-client-header__menu-link--active"
          >
            {{ link.label }}
          </NuxtLink>
        </li>
      </ul>

      <div class="mm-client-header__container-header__col">
        <div
          class="header__menu--mobile-top"
          @click="isMobileMenu = !isMobileMenu"
        >
          <SvgIcon src="navigation/menu" />
        </div>
      </div>

      <div
        class="header__overlay"
        :class="{ closed: !isMobileMenu }"
        @click="isMobileMenu = !isMobileMenu"
      >
        <div
          class="header__menu--mobile"
          :class="{ closed: !isMobileMenu }"
        >
          <div class="menu__header">
            <div class="logo">
              <NuxtLink to="/">
                <BaseImg
                  src="/images/logo-desc-off.svg"
                  alt=""
                  class="logo--desc-off"
                />
              </NuxtLink>
            </div>

            <span
              class="close-icon"
              @click="isMobileMenu = !isMobileMenu"
            >
              <SvgIcon src="navigation/close-24px" />
            </span>
          </div>

          <div class="menu__address">
            <span class="address__title">Ваш адрес доставки</span>
            <span class="address__main">Красноярск, Лесосибирск, Базис 2
              <span class="address__action">
                <SvgIcon src="action/pen-edit" />
              </span>
            </span>
          </div>

          <div class="menu__contacts">
            <a
              v-for="(item, key) in clientMenu"
              :key="key"
              :href="sanitizeUrl(item.path)"
              class="conatcts__link"
            >{{ item.label }}</a>

            <button class="btn btn-outlined">
              Свяжитесь с нами
            </button>
          </div>
        </div>
      </div>

      <div class="mm-client-header__account mm-caption-regular">
        <div class="mm-client-header__account-icon">
          <LoginButton />
        </div>
      </div>
    </div>
    <MobileMenu />
  </header>
</template>

<script setup lang="ts">
import IMenuLinkItem from 'shared/models/linkItem.model';

import LoginButton from 'components/LoginButton.vue';
import { useUserStore } from 'store/user.store';
import { MenuHelper } from 'utils/menuHelper.util';
import MobileMenu from 'components/MobileMenu.vue';
import { storeToRefs } from 'pinia';
import BaseImg from 'shared/components/BaseImg.vue';
import { RouterHelper } from 'shared/utils/routerHelper.util';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { useInternalStore } from 'shared/store/internalStore.store';

const logoSrc = '/images/logo-desc-off.svg';

const userStore = useUserStore();
const internalStore = useInternalStore();
const $route = useRoute();
const { mpAdminInfoEmail } = storeToRefs(useUserStore());

const isMobileMenu = ref<boolean>(false);

const clientMenu = computed(
  () => MenuHelper.getHeaderLinks(
    userStore?.groups,
    undefined,
    undefined,
    internalStore.getIsInternalUser,
  ),
);

function isHeaderMenuRendered() {
  return clientMenu.value.map((menuitem) => menuitem.path).includes($route.path) || $route.path.includes('/client');
}

function isActiveLink(linkItem: IMenuLinkItem) {
  return RouterHelper.includePath(linkItem.path, $route);
}
</script>

<style scoped lang="scss">
@import 'styles/base/common/variables';

$root: '.mm-client-header';

.mm-client-header {
  width: 100%;

  &__container {
    padding: 32px 40px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1600px;
    margin: 0 auto;
    position: relative;

    &-header__col {
      display: none;
    }

    &::after {
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      left: 40px;
      right: 40px;
      bottom: 0;
      background-color: $client-header-border-color;
    }
  }

  &__account {
    display: flex;
    align-content: center;
    padding-top: 4px;
    font-size: 14px;
    line-height: 22px;
  }

  &__menu {
    display: flex;
    align-self: flex-end;
    padding: 0;
    margin: 0;
  }

  &__menu-item {
    display: flex;
    list-style-type: none;

    &:not(:first-child) {
      margin-left: 28px;
    }
  }

  &__menu-link {
    display: flex;
    padding: 4px 12px 36px;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    height: 100%;
    text-decoration: none;
    cursor: pointer;
    color: $light-green;

    &--active {
      color: $text-black;
      font-weight: 500;

      &::after {
        display: block;
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        z-index: 10;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        width: 100%;
        height: 3px;
        background: $main-green;
      }
    }
  }

  &__account-icon {
    cursor: pointer;

    :deep(svg) {
      margin-left: 16px;
      width: 24px;
      height: 16px;
    }
  }
}

.header__item-link--active {
  color: $link;

  svg path {
    fill: $link;
  }
}

@media screen and (max-width: 1023px) {
  .mm-client-header {
    &__container {
      padding: 20px 20px 22px;

      &::after {
        left: 20px;
        right: 20px;
      }

      .header__overlay {
        z-index: 19;
      }

      &-header__col {
        display: flex;
      }
    }

    &__menu {
      display: none;
    }

    &__account {
      display: none;
    }
  }
}
</style>
